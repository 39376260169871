import React, { useEffect, useRef, useState } from "react";
import { GoogleMap, useLoadScript, Marker, InfoWindow } from "@react-google-maps/api";
import markerHover from '../../../assets/images/icons/Marker_hover.svg'
import Button from '../../atoms/button/button';
import Description from "../../atoms/description/description";
import InfoBox from "../../molecules/info-box/infoBox";
import './map.scss';

interface IMap {
   big?: boolean;
   nombreCategoria?: string;
   tituloUbicacion?:string;
   tituloBoton?: string;
   redireccionBoton?: string;
   referenciaMapa?:any;
   descripcion?:any;
   tituloGeneral?: string;
   evento?: string;
   validarBotonAutenticacion?: boolean;
   referenciaUbicaciones?:Array <{
      titulo:string;
      tituloUbicacion:string;
      localizacion: {
         lat: number,
         lon: number
      }
   }>
}

const libraries: any = ["places"];

const Map = React.memo((props:IMap) => {

   const { isLoaded, loadError } = useLoadScript({
      googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY,
      libraries,
   });
   const [selected, setSelected] = useState<any | null>(null);
   const [mapStyles, setMapStyles] = useState<any | null>();
   const [chosen, setChosen] = useState<number | null>(null);
   const [iconZoomH, setIconZoomH] = useState<any>(58);
   const [iconZoomW, setIconZoomW] = useState<any>(58);
   const [defaultCenter, setDefaultCenter] = useState<any | null>({ lat: 6.247771749, lng: -75.56979932 });
  
   const mapRef = useRef(null);
   function handleLoad(map: any) {
      mapRef.current = map;
   }

   useEffect(() => {
      if (props.snazzyMap) {
         setMapStyles(JSON.parse(JSON.stringify(props.snazzyMap)))
      }
   }, [])

   useEffect(() => {
      if (props.referenciaMapa) {
         setDefaultCenter({ lat: props.referenciaMapa[0].location.lat, lng: props.referenciaMapa[0].location.lon })
      }
   }, [])

   function handleCenter() {
      if (!mapRef.current) return;
      let currentMap: any = mapRef.current;
      const newCenter = currentMap.getCenter().toJSON();
      setDefaultCenter(newCenter);
    }

   if (loadError)
      return <div>Error al cargar el mapa. Actualice la página.</div>;
   if (!isLoaded) return <div>Cargando mapa...</div>;

   return( 
      <section className={`o-map ${ props.big ? 'o-map--big' : '' }`} >
         { props.tituloGeneral || props.tituloUbicacion ?
            (<h2 className="o-map__title">{props.tituloGeneral ? props.tituloGeneral : props.tituloUbicacion}</h2>)
            : null
         }
         { props.descripcion &&
            <div className="o-map__description">
               <Description
                  description={JSON.stringify(props.descripcion.json)}
               />
            </div>
         }
         <div className={`o-map__container ${ props.big ? 'o-map__container--big' : '' }`}>
            <GoogleMap
               id="map"
               mapContainerStyle={{
                  height: "100%",
                  width: "100%",
               }}
               zoom={props.evento ? parseInt(props.evento) : 12}
               center={defaultCenter}
               onLoad={handleLoad}
               onDragStart={handleCenter}
               options={{scrollwheel: false, styles: mapStyles}}
            >
               { !props.validarBotonAutenticacion && (
                     props.referenciaMapa ?
                     props.referenciaMapa.map((sede, i) => (
                        <Marker
                           key={i}
                           icon={{
                              url:
                                 sede.image[1] ? sede.image[1].file.url : markerHover,
                                 scaledSize: chosen === i ? new window.google.maps.Size(iconZoomH, iconZoomW) : new window.google.maps.Size(58, 58),
                           }}
                           position={{
                              lat: sede.location.lat,
                              lng: sede.location.lon,
                           }}
                           title={sede.name}
                           onClick={() => {
                              setSelected(sede);
                           }}
                           onMouseOver={() => {
                              setChosen(i)
                              setIconZoomH(70);
                              setIconZoomW(70);
                            }}
                           onMouseOut={() => {
                              setChosen(null)
                              setIconZoomH(58);
                              setIconZoomW(58);
                           }}
                        ></Marker>
                     ))
                  : 
                  props.referenciaUbicaciones?.map((sede, i) => (
                     <Marker
                        key={i}
                        icon={{
                           url:
                           markerHover,
                              scaledSize: new window.google.maps.Size(70, 70),
                        }}
                        position={{
                           lat: sede.localizacion.lat,
                           lng: sede.localizacion.lon,
                        }}
                        title={sede.tituloUbicacion}
                     ></Marker>
                  ))
               )}
               { selected && (
                  <InfoWindow
                     position={{
                        lat: selected.location.lat,
                        lng: selected.location.lon,
                     }}
                     onCloseClick={() => {
                        setSelected(null);
                     }}
                  >
                     <InfoBox sede={selected} zoom={props.evento ? parseInt(props.evento) : 12} tituloEnlace={selected.slug ? selected.tituloEnlace : ""} />
                  </InfoWindow>
               )}
            </GoogleMap>
         </div>
         { props.tituloBoton &&
            <Button 
               classname='o-map__button' 
               isDisabled={false}
               type={'link'}
               href={props.redireccionBoton}
               text={props.tituloBoton}
               strictCss={true}
               gtmMark={'gtm'}
            />
         }
      </section>
   )
})

export default Map;
