import React from "react";
import "./description.scss";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

interface DescriptionInterface {
	description: any;
   noCentered?: boolean;
}

const Description = (props: DescriptionInterface) => {

   const { description, noCentered } = props;

   const Bold = (children:any) => (
      <b className="a-description__text--bold">{children}</b>
   );
   
   const Text = (children:any) => (
      <p className={`a-description__text ${noCentered && '--no-centered'}`}>{children}<br/><br/></p>
   )

   const List = (children:any) => (
      <div className="a-description__list">
         <li className="a-description__list__list">{children}</li>
      </div>
   )
   
   const options = {
      renderMark: {
         [MARKS.BOLD]: (text:any) => Bold(text)
      },
      renderNode: {
         [BLOCKS.LIST_ITEM]: (_node:any, children:any) => List(children),
         [BLOCKS.PARAGRAPH]: (_node:any, children:any) => Text(children)
      },
   };

	return (  
      <div className={`a-description ${noCentered ? '--no-centered' : ''}`}>
         {documentToReactComponents(JSON.parse(description), options)}
      </div>
   )
}

export default Description
