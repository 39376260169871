import React from "react";
import "./infoBox.scss";

type InfoBoxProps = {
  sede: any;
  zoom: number;
  tituloEnlace?: string;
};

export default function InfoBox( { sede, zoom, tituloEnlace }: InfoBoxProps) {

  const handleClick = async () => {
    if (sede.slug.includes('comfama.com')) {
      window.location.href = sede.slug;
    } else {
      window.open(sede.slug, '_blank');
    }
  }

  const renderLink = ( conditional, string ) => {
    if ( conditional ) {
      return (
        <div className="m-infobox__container__info__link">
          <p className="m-infobox__container__info__link--decoration" onClick={handleClick}>{ string }</p>
        </div>
      )
    }
    return null
  }

  return (
    <div className="m-infobox__container">
      <div
        className="m-infobox__container__img"
        style={{ backgroundImage: `url(${sede.image[0].file.url})` }}
      ></div>
      <div className="m-infobox__container__info" >
        <span className="m-infobox__container__info__name">{ sede.name }</span>
        { sede.address &&
          <span className="m-infobox__container__info__subtitle">{ sede.address }</span>
        }
        { !tituloEnlace ?
            renderLink(zoom === 10, '¿Cómo llegar?')
          :
            renderLink(tituloEnlace !== "", tituloEnlace)
        }
      </div>
    </div>
  );
}
